import React from 'react';
import moment from 'moment';
import '../App.css';
import { HourTitle, HourSelector, ConfirmButton, DateSelector } from './components';
import { retrieveToken, retrieveAgenda, preBookSchedule } from './controller';
import { Spinner, Modal, ModalSection } from './reusable';
import './Schedule.css';

import toast from 'toasted-notes' ;
import 'toasted-notes/src/styles.css';

class Schedule extends React.Component {
  constructor(props) {
    super(props);
    // console.log("Coinstruiu");
    const date = new Date();
    this.state = {
      agenda: {},
      isFetching: false,
      day: date,
      hour: '',
      agendaError: '',
      token: '',
      patientId: null,
      therapistId: null,
      bookingError: null,
      showError: false,
      isBooking: false
    };
  }

  componentDidMount() {
    const fullPath = window.location.href;
    // const fullPath = 'http://localhost:8000/agendamento/2/119/';
    console.log('NO DID MOUNT -> ', fullPath);
    const patientId = fullPath.split('/').splice(-2)[0];
    const therapistId = fullPath.split('/').splice(-3)[0];
    const { day } = this.state;
    console.log(patientId, therapistId)
    retrieveToken(patientId).then(token => {
      this.setState({token, patientId, therapistId});
      this.daySelection(day, therapistId, token);
    });
    // console.log('ESTADO DO COMPONENTE: ', this.state);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // console.log('TA NO DID UPDATE: ', prevState, this.state)
    // PARA BOTAR O showError NOVAMENTE COMO FALSE DEPOIS DE TER RENDERIZADO O toast
    if (prevState.showError !== this.state.showError) {
      // console.log('TROCOU')
      this.setState({showError: false});
    }
  }

  hourSelection = (hour) => {
    // console.log('VAI MUDAR O STATE')
    this.setState({ hour });
  };

  toggleError = () => {
    // console.log('VAI TOGGLE ERROR')
    this.setState({showError: !this.state.showError});
  };

  scheduleAppointment = () => {
    console.log("VAI AGENDAR")
    const { day, hour, therapistId, patientId, token } = this.state;
    console.log(this.state);
    const date = moment(day).format('YYYY-MM-DD');
    const schedule = `${date} ${hour}`;
    this.setState({isBooking: true});
    preBookSchedule(schedule, patientId, therapistId, token)
      .then(res => {
        // console.log('RESPONSE NO SCHEDULE: ', res);
        const appointment_id = res.appointment.id;
        this.setState({isBooking: false});
        // window.location.href = `http://localhost:8000/planos/psicologo/${therapistId}/${patientId}/${appointment_id}`;
        window.location.href = `https://webapp.moodar.com.br/planos/psicologo/${therapistId}/${patientId}/${appointment_id}`;
      })
      .catch(error => {
        // console.log('ERRO NO SCHEDULE: ', error, schedule);
        this.setState({isBooking: false, day, bookingError: error, isFetching: false, showError: true})
      });
  };

  daySelection = (day) => {
    // console.log('--------------------- CLICOU NO DIA ', day)
    const { therapistId, token } = this.state;

    // SE FOR O MESMO DIA NA VERDADE NO CALENDARIO TEM Q SER O PROXIMO
    const nextDate = new Date();
    const currentDay = day.toDateString() === nextDate.toDateString() ? new Date(nextDate.setDate(nextDate.getDate() + 1)) : day;
    const date = moment(currentDay).format('YYYY-MM-DD');
    console.log('DATES: ', date, currentDay)

    // Ja coloca o dia aqui para quando ele trocar de mes nao ter que renderizar o calendario com a data do mes anterior
    this.setState({ isFetching: true, hour: '', day: currentDay });

    retrieveAgenda(date, therapistId, token)
      .then(agenda => this.setState({agenda, day: currentDay, isFetching: false}))
      .catch(error => {
        console.log('ERRO NO AGENDA: ', error);
        this.setState({day: currentDay, agendaError: error, agenda: {}, isFetching: false})
      });
  };

  render() {
    const { agenda, isFetching, day, hour, agendaError, bookingError, showError, isBooking } = this.state;
    console.log('STATE: ', this.state)
    return (
      <div className="App">
        <DateSelector day={day} setDay={this.daySelection} />
        {
          bookingError && showError && (
            toast.notify(bookingError, { position: 'top', duration: null })
          )
        }
        <section id="inicio" className="center white-bg">
          { isFetching ? (
              <div style={{padding: "2rem"}}>
                <Spinner />
              </div>
            )
            : (
              <HourSelector
                agenda={agenda}
                setHour={this.hourSelection}
                hour={hour}
                agendaError={agendaError}
              />
            )
          }
          <ConfirmButton active={!!(day && hour)} onPress={this.scheduleAppointment} text="AGENDAR CONSULTA" />
        </section>
        { isBooking && (
          <Modal>
            <ModalSection>
              <div style={{padding: "2rem", opacity: 1}}>
                <h5 style={{marginBottom: '1rem'}}>Reservando sua consulta</h5>
                <Spinner />
              </div>
            </ModalSection>
          </Modal>)}
      </div>
    )
  }
};

export default Schedule;
