import styled from 'styled-components';

export const Title = styled.button`
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem;
  width: 5rem;
  border-radius: 5px;
  margin-top: 1rem;
  background-color: ${props => props.backgroundColor}

  &:hover {
    background-color: black;
  }

  @media (max-width: 500px) {
    width: 25%
  }
`;

export const Agenda = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;

  @media (max-width: 500px) {
    max-height: 30vh;
    overflow-y: scroll;
  }
`;

export const ScheduleButton = styled.button`
  background-color: #00D963;
  height: 3rem;
  width: 100%;
  color: white;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;
