import styled, { keyframes } from "styled-components";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  width: ${props => props.size};
  height: ${props => props.size};
  margin: auto;
  border-radius: 100%;
  border: 1px solid ${props => props.bgColor};
  border-top-color: ${props => props.fgColor};
  animation: ${rotate} 1s linear infinite;
`;

Spinner.defaultProps = {
  size: "4em",
  fgColor: "#eb5c74",
  bgColor: "#ccc"
};

export const ModalSection = styled.div`
  position: absolute;
  width: 80%;
  bottom: 45%;
  left: 10%;
  ${'' /* left: 40px;
  right: 40px;
  bottom: 40px; */}
  ${'' /* border: 1px solid rgb(204, 204, 204);
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px; */}
  outline: none;
  opacity: 1;

  @media (min-width: 500px) {
    height: 20%;
    width: 20%;
    bottom: 50%;
    left: 40%;
    right:40%;
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;
