// @flow

import React from 'react';
// import moment from 'moment';
// import { Colors } from '../../assets/variables';
// import { DialogModal } from '../../common-components';
import { Title, Agenda, ScheduleButton } from './components-styles';
import Calendar from 'react-calendar';
// import styles from './Schedule.module.css';
import './Schedule.css';

type WrapperProps = {
  children: any
};

// export const Wrapper = (props: WrapperProps) => {
//   const { children } = props;
//   return <Scrolldiv contentContainerStyle={styles.container}>{children}</Scrolldiv>;
// };

// type TitleProps = {
//   title: string,
//   backColor?: string
// };

// export const Title = (props: TitleProps) => {
//   const { title, backColor = 'transparent' } = props;
//   return <p style={[styles.title, { backgroundColor: backColor }]}>{title}</p>;
// };

export const HourTitle = (props) => {
  const { title, backColor = 'red' } = props;
  return <Title backgroundColor={backColor} >{title}</Title>;
};

type DateSelectorProps = {
  day: string,
  setDay: string => void,
  allowedDay: string
};

// export const DateSelector = (props: DateSelectorProps) => {
//   const { day, setDay, allowedDay } = props;
//
//   return (
//     <Calendar
//       current={day === moment().format('YYYY-MM-DD') ? allowedDay : day}
//       minDate={allowedDay}
//       maxDate={moment()
//         .add(4, 'week')
//         .format('YYYY-MM-DD')}
//       monthFormat="MMMM, yyyy"
//       onDayPress={day => {
//         console.log('selected day', day);
//         setDay(day.dateString);
//       }}
//       markedDates={{
//         [day]: { selected: true },
//       }}
//       theme={{
//         textMonthFontSize: 25,
//         arrowColor: Colors.secondary,
//         monthpColor: Colors.secondary,
//         selectedDayBackgroundColor: Colors.green3,
//         textMonthFontWeight: 'normal',
//         todaypColor: Colors.secondary,
//       }}
//     />
//   );
// };
export const DateSelector = (props) => {
  const { day, setDay } = props;
  console.log('VALUE NO DATESELECTOR: ', day, day.toDateString());
  let nextDate = new Date();
  // SE FOR O MESMO DIA NA VERDADE NO CALENDARIO TEM Q SER O PROXIMO
  const currentDay = day.toDateString() === nextDate.toDateString() ? nextDate : day;
  nextDate.setDate(nextDate.getDate() + 1);
  let lastDate = new Date();
  lastDate.setDate(lastDate.getDate() + 15);

  return (
    <Calendar
      className={"center"}
      onChange={setDay}
      value={currentDay}
      defaultValue={new Date()}
      minDate={nextDate}
      maxDate={lastDate}
      locale={'pt-BR'}
    />
  );
};

type SpinnerProps = {};

// export const Spinner = (props: SpinnerProps) => {
//   return <ActivityIndicator style={{ flex: 1 }} color={Colors.secondary} size="large" />;
// };

// type HourSelectorProps = {
//   agenda: Object,
//   hour: string,
//   setHour: string => void,
//   agendaError: string
// };
// const styles = {
//   selected: {
//     backgroundColor: '#00D963',
//     color: 'green'
//   }
// };

export const HourSelector = (props) => {
  const { agenda, hour, setHour, agendaError } = props;
  // const error = agendaError.toString().split(': ');
  const error = agendaError;
  // Checking whether it is an {}. If so, there was an error message on the retrieveTimeSlots request
  const hasAgenda = !!Object.keys(agenda).length;
  // const hasAgenda = true;
  return (
    <Agenda>
      {hasAgenda ? (
        Object.keys(agenda).map(h => {
          // const busyStyle = agenda[h] ? {} : styles.disabled;
          const selectionStyle = h === hour ? 'selected' : '';
          // const Hour = agenda[h] ? button : div;

          return (
            <Title className={selectionStyle} key={h} onClick={() => setHour(h)}>
                {h.replace(':', 'h').replace('00', '')}
            </Title>
          );
        })
      ) : (
        <p>{error}</p>
      )}
    </Agenda>
  );
};

type ConfirmButtonProps = {
  active: boolean,
  onPress: void => void,
  text: string
};

export const ConfirmButton = (props) => {
  const { active, onPress, text } = props;
  console.log('BUTTON: ', active)
  const validStyle = active ? {} : { opacity: 0.5 };
  // const Button = active ? TouchableOpacity : div;

  return (
    <ScheduleButton style={validStyle} onClick={onPress}>
      {/* <p style={styles.label}>{text}</p> */}
      {text}
    </ScheduleButton>
  );
};
